import {noop}                                                          from 'lodash';
import Vue                                                             from 'vue'
import Router                                                          from 'vue-router'
import store                                                           from '../store/index.js';
import {PendingUserRights}                                             from '../../shared/userRights.js';
import {validateGalenicaFeaturesEnabled, validateMazarsReportsEnabled} from '../lib/helpers/validateCustomerCustomFnEnabled.js';

const Infopanel          = () => import(/* webpackChunkName: "Infopanel" */ '../components/Infopanel.vue');
const CompanySettings    = () => import(/* webpackChunkName: "CompanySettings" */ '../components/CompanySettings/CompanySettings.vue');
const BokforingSettings  = () => import(/* webpackChunkName: "BokforingSettings" */ '../components/BokforingSettings/BokforingSettings.vue');
const SysData            = () => import(/* webpackChunkName: "SysData" */ '../components/SysData/SysData.vue');
const DigitalMailbox     = () => import(/* webpackChunkName: "DigitalMailbox" */ '../components/DigitalMailbox/DigitalMailbox.vue');
const Integrations       = () => import(/* webpackChunkName: "Integrations" */ '../components/Integrations/Integrations.vue');
const Integration        = () => import(/* webpackChunkName: "Integration" */ '../components/Integrations/Integration.vue');
const Users              = () => import(/* webpackChunkName: "Users" */ '../components/Users/Users.vue');
const User               = () => import(/* webpackChunkName: "User" */ '../components/Users/User.vue');
const UserRightsGroups   = () => import(/* webpackChunkName: "UserRightsGroups" */ '../components/UserRightsGroups/UserRightsGroups.vue');
const SalaryPeriods      = () => import(/* webpackChunkName: "SalaryPeriods" */ '../components/SalaryPeriods/SalaryPeriods.vue');
const Kortnummer         = () => import(/* webpackChunkName: "Kortnummer" */ '../components/Kortnummer/Kortnummer.vue');
const MySettings         = () => import(/* webpackChunkName: "MySettings" */ '../components/MySettings.vue');
const RequestDemoAccount = () => import(/* webpackChunkName: "RequestDemoAccount" */ '../components/RequestDemoAccount.vue');

// import GettingStarted from '../components/GettingStarted';
const Holidays                    = () => import(/* webpackChunkName: "Holidays" */ '../components/Holidays.vue');
const Arbschema                   = () => import(/* webpackChunkName: "Arbschema" */ '../components/Arbschema/ArbSchema.vue');
const ArbschemaForm               = () => import(/* webpackChunkName: "ArbschemaForm" */ '../components/Arbschema/ArbSchemaForm.vue');
const Dagschema                   = () => import(/* webpackChunkName: "Dagschema" */ '../components/Dagschema/Dagschema.vue');
const DagschemaForm               = () => import(/* webpackChunkName: "DagschemaForm" */ '../components/Dagschema/DagSchemaForm.vue');
const OBRegler                    = () => import(/* webpackChunkName: "OBRegler" */ '../components/OBRegler/OBRegler.vue');
const OBRegelForm                 = () => import(/* webpackChunkName: "OBRegelForm" */ '../components/OBRegler/OBRegelForm.vue');
const JorRegler                   = () => import(/* webpackChunkName: "JorRegler" */ '../components/JorRegler/JorRegler.vue');
const JorRegelForm                = () => import(/* webpackChunkName: "JorRegelForm" */ '../components/JorRegler/JorRegelForm.vue');
const BerRegler                   = () => import(/* webpackChunkName: "BerRegler" */ '../components/BerRegler/BerRegler.vue');
const BerRegelForm                = () => import(/* webpackChunkName: "BerRegelForm" */ '../components/BerRegler/BerRegelForm.vue');
const OvtRegler                   = () => import(/* webpackChunkName: "OvtRegler" */ '../components/OvtRegler/OvtRegler.vue');
const OvtRegelForm                = () => import(/* webpackChunkName: "OvtRegelForm" */ '../components/OvtRegler/OvtRegelForm.vue');
const TidKoder                    = () => import(/* webpackChunkName: "TidKoder" */ '../components/TidKoder.vue');
const LonKoder                    = () => import(/* webpackChunkName: "LonKoder" */ '../components/LonKoder.vue');
const Varugrupper                 = () => import(/* webpackChunkName: "Varugrupper" */ '../components/Varugrupper.vue');
const FriaAckarDef                = () => import(/* webpackChunkName: "FriaAckarDef" */ '../components/FriaAckarDef.vue');
const Egenskaper                  = () => import(/* webpackChunkName: "Egenskaper" */ '../components/Egenskaper.vue');
const BrukarReg                   = () => import(/* webpackChunkName: "BrukarReg" */ '../components/BrukarReg/BrukarReg.vue');
const Brukarkort                  = () => import(/* webpackChunkName: "Brukarkort" */ '../components/BrukarReg/Brukarkort.vue');
const Resultatenheter             = () => import(/* webpackChunkName: "Resultatenheter" */ '../components/Resultatenheter/Resultatenheter.vue');
const Personalregister            = () => import(/* webpackChunkName: "Personalregister" */ '../components/Personalregister/Personalregister.vue');
const Personkort                  = () => import(/* webpackChunkName: "Personkort" */ '../components/Personalregister/Personkort.vue');
const StdPersoner                 = () => import(/* webpackChunkName: "StdPersoner" */ '../components/Personalregister/StdPersoner.vue');
const StdPersonkort               = () => import(/* webpackChunkName: "StdPersonKort" */ '../components/Personalregister/StdPersonkort.vue');
const Loneartsregister            = () => import(/* webpackChunkName: "Loneartsregister" */ '../components/Loneartsregister/Loneartsregister.vue');
const LoneartForm                 = () => import(/* webpackChunkName: "LoneartForm" */ '../components/Loneartsregister/LoneartForm.vue');
const Loneartsstyrning            = () => import(/* webpackChunkName: "Loneartsstyrning" */ '../components/Loneartsstyrning/Loneartsstyrning.vue');
const Loneartstabeller            = () => import(/* webpackChunkName: "Loneartstabeller" */ '../components/Loneartstabeller/Loneartstabeller.vue');
const Loneartstabell              = () => import(/* webpackChunkName: "Loneartstabell" */ '../components/Loneartstabeller/Loneartstabell.vue');
const CustomFormelVariables       = () => import(/* webpackChunkName: "CustomFormelVariables" */ '../components/CustomFormelVariables/CustomFormelVariables.vue');
const PayslipsOverview            = () => import(/* webpackChunkName: "PayslipsOverview" */ '../components/Payslips/PayslipsOverview.vue');
const PayslipList                 = () => import(/* webpackChunkName: "PayslipList" */ '../components/Payslips/PayslipList.vue');
const Payslip                     = () => import(/* webpackChunkName: "Payslip" */ '../components/Payslips/Payslip.vue');
const EmployerDeclarationOverview = () => import(/* webpackChunkName: "EmployerDeclarationOverview" */ '../components/EmployerDeclaration/EmployerDeclarationOverview.vue');
const EmployerDeclaration         = () => import(/* webpackChunkName: "EmployerDeclaration" */ '../components/EmployerDeclaration/EmployerDeclaration.vue');
const BokforingsunderlagOverview  = () => import(/* webpackChunkName: "BokforingsunderlagOverview" */ '../components/Bokforingsunderlag/BokforingsunderlagOverview.vue');
const BokforingsunderlagList      = () => import(/* webpackChunkName: "BokforingsunderlagList" */ '../components/Bokforingsunderlag/BokforingsunderlagList.vue');
const Bokforingsunderlag          = () => import(/* webpackChunkName: "Bokforingsunderlag" */ '../components/Bokforingsunderlag/Bokforingsunderlag.vue');
const Agreements                  = () => import(/* webpackChunkName: "Agreements" */ '../components/Agreements/Agreements.vue');
const VacationAgreements          = () => import(/* webpackChunkName: "VacationAgreements" */ '../components/VacationAgreements/VacationAgreements.vue');
const LoneuppdragList             = () => import(/* webpackChunkName: "LoneuppdragList" */ '../components/Loneuppdrag/LoneuppdragList.vue');
const Loneuppdrag                 = () => import(/* webpackChunkName: "Loneuppdrag" */ '../components/Loneuppdrag/Loneuppdrag.vue');
const Kalendarie                  = () => import(/* webpackChunkName: "Kalendarie" */ '../components/Kalendarie/Kalendarie.vue');
const Tidrapport                  = () => import(/* webpackChunkName: "Tidrapport" */ '../components/Tidrapport/Tidrapport.vue');
const FOS                         = () => import(/* webpackChunkName: "FOS" */ '../components/FOS/FOS.vue');
const Fora                        = () => import(/* webpackChunkName: "Fora" */ '../components/Fora/Fora.vue');
const Fordelningsnycklar          = () => import(/* webpackChunkName: "Fordelningsnycklar" */ '../components/Fordelningsnycklar/Fordelningsnycklar.vue');
const Fordelningsnyckel           = () => import(/* webpackChunkName: "Fordelningsnyckel" */ '../components/Fordelningsnycklar/Fordelningsnyckel.vue');
const Narvarostatus               = () => import(/* webpackChunkName: "Narvarostatus" */ '../components/Narvarostatus.vue');
const Departments                 = () => import(/* webpackChunkName: "Departments" */ '../components/Departments/Departments.vue');
const Ersattningar                = () => import(/* webpackChunkName: "Ersattningar" */ '../components/Ersattningar/Ersattningar.vue');
const ErsattningForm              = () => import(/* webpackChunkName: "ErsattningForm" */ '../components/Ersattningar/ErsattningForm.vue');
const KontoReg                    = () => import(/* webpackChunkName: "KontoReg" */ '../components/KontoReg.vue');
const GalenicaKeyFiguresReport    = () => import(/* webpackChunkName: "GalenicaKeyFiguresReport" */ '../components/Rapporter/Custom/GalenicaKeyFiguresReport.vue');
const MazarsSpecialReports             = () => import(/* webpackChunkName: "MazarsSpecialReports" */ '../components/Rapporter/Custom/MazarsSpecialReports.vue');
const Bruttolonelista             = () => import(/* webpackChunkName: "Bruttolonelista" */ '../components/Rapporter/Bruttolonelista.vue');
const Lonestatistik               = () => import(/* webpackChunkName: "Lonestatistik" */ '../components/Rapporter/Lonestatistik.vue');
const Saldoskuld                  = () => import(/* webpackChunkName: "Saldoskuld" */ '../components/Rapporter/Saldoskuld/Saldoskuld.vue');
const LASLista                    = () => import(/* webpackChunkName: "LASLista" */ '../components/Rapporter/LASLista.vue');
const Rehablista                  = () => import(/* webpackChunkName: "Rehablista" */ '../components/Rapporter/Rehablista.vue');
const FTEHeadcount                = () => import(/* webpackChunkName: "FTEHeadcount" */ '../components/Rapporter/FTEHeadcount/FTEHeadcount.vue');
const TimeJournals                  = () => import(/* webpackChunkName: "TimeJournals" */ '../components/Rapporter/TimeJournals.vue');
const AvtalsLista               = () => import(/* webpackChunkName: "Avtalslista" */ '../components/Rapporter/AvtalsLista.vue');
const Personallista               = () => import(/* webpackChunkName: "Personallista" */ '../components/Rapporter/Personallista.vue');
const BrukarRegLista              = () => import(/* webpackChunkName: "BrukarRegLista" */ '../components/Rapporter/BrukarRegLista.vue');
const BrukarPerLista              = () => import(/* webpackChunkName: "BrukarPerLista" */ '../components/Rapporter/BrukarPerLista.vue');
const PersonkortRap               = () => import(/* webpackChunkName: "Personkort" */ '../components/Rapporter/Personkort.vue');
const PerKontaktRap               = () => import(/* webpackChunkName: "PerKontaktRap" */ '../components/Rapporter/PerKontakt.vue');
const Debiteringskonto            = () => import(/* webpackChunkName: "Debiteringskonto" */ '../components/Rapporter/Debiteringskonto.vue');
const Fackavgifter                = () => import(/* webpackChunkName: "Fackavgifter" */ '../components/Rapporter/Fackavgifter.vue');
const Loneutmatning               = () => import(/* webpackChunkName: "Loneutmatning" */ '../components/Rapporter/Loneutmatning.vue');
const Loneutveckling              = () => import(/* webpackChunkName: "Loneutveckling" */ '../components/Rapporter/Loneutveckling.vue');
const ForskottUtbetalning         = () => import(/* webpackChunkName: "ForskottUtbetalning" */ '../components/ForskottUtbetalning/ForskottUtbetalning.vue');
const SCB                         = () => import(/* webpackChunkName: "SCB" */ '../components/SCB/SCB.vue');
const SAF                         = () => import(/* webpackChunkName: "SAF" */ '../components/SAF/SAF.vue');
const KFO                         = () => import(/* webpackChunkName: "KFO" */ '../components/KFO/KFO.vue');
const KPBer                       = () => import(/* webpackChunkName: "KPBer" */ '../components/KPBer/KPBer.vue');
const KPBerRad                    = () => import(/* webpackChunkName: "KPBerRad" */ '../components/KPBer/KPBerRad.vue');
const LOSEN                       = () => import(/* webpackChunkName: "LOSEN" */ '../components/LOSEN/LOSEN.vue');
const LOSENPersonal               = () => import(/* webpackChunkName: "LOSENPersonal" */ '../components/LOSEN/LOSENPersonal.vue');
const LOSENDetails                = () => import(/* webpackChunkName: "LOSENDetails" */ '../components/LOSEN/LOSENDetails.vue');
const Collectum                   = () => import(/* webpackChunkName: "Collectum" */ '../components/Collectum/Collectum.vue');
const FackFor                     = () => import(/* webpackChunkName: "FackFor" */ '../components/FackFor/FackFor.vue');
const ImpReg                      = () => import(/* webpackChunkName: "ImpReg" */ '../components/ImpReg/ImpReg.vue');
const RetroLon                    = () => import(/* webpackChunkName: "RetroLon" */ '../components/RetroLon/RetroLon.vue');
const RetroLonDetails             = () => import(/* webpackChunkName: "RetroLonDetails" */ '../components/RetroLon/RetroLonDetails.vue');
const Lonerevision                = () => import(/* webpackChunkName: "Lonerevision" */ '../components/Lonerevision/Lonerevision.vue');
const LonerevisionDetails         = () => import(/* webpackChunkName: "LonerevisionDetails" */ '../components/Lonerevision/LonerevisionDetails.vue');
const Printouts                   = () => import(/* webpackChunkName: "Printouts" */ '../components/Printouts.vue');
const ExportSettings              = () => import(/* webpackChunkName: "ExportSettings" */ '../components/ExportSettings.vue');
const SemberedningList            = () => import(/* webpackChunkName: "SemberedningList" */ '../components/Semberedning/SemberedningList.vue');
const SemberedningDetails         = () => import(/* webpackChunkName: "SemberedningDetails" */ '../components/Semberedning/SemberedningDetails.vue');
const Kontrolluppgift             = () => import(/* webpackChunkName: "Kontrolluppgift" */ '../components/Kontrolluppgift/Kontrolluppgift.vue');
const Documents                   = () => import(/* webpackChunkName: "Documents" */ '../components/Documents/Documents.vue');
const Lonekartlaggning            = () => import(/* webpackChunkName: "Lonekartlaggning" */ '../components/Lonekartlaggning/Lonekartlaggning.vue');
const DataTreeSearch              = () => import(/* webpackChunkName: "DataTreeSearch" */ '../components/DataTreeSearch/DataTreeSearch.vue');
const Messages                    = () => import(/* webpackChunkName: "Messages" */ '../components/Messages/Messages.vue');
const Loneartslista               = () => import(/* webpackChunkName: "Loneartslista" */ '../components/Rapporter/Loneartslista.vue');
const Resultatrapport             = () => import(/* webpackChunkName: "Resultatrapport" */ '../components/Rapporter/Resultatrapport.vue');
const FKSjukanmalan               = () => import(/* webpackChunkName: "FKSjukanmalan" */ '../components/FKSjukanmalan/FKSjukanmalan.vue');

const LSSBeredningOverview = () => import(/* webpackChunkName: "LSSBeredningOverview" */ '../components/LSS/LSSBeredningOverview.vue');
const LSSBeredningList     = () => import(/* webpackChunkName: "LSSBeredningList" */ '../components/LSS/LSSBeredningList.vue');
const LSSBeredning         = () => import(/* webpackChunkName: "LSSBeredning" */ '../components/LSS/LSSBeredning.vue');
const LSSSettings          = () => import(/* webpackChunkName: "LSSSettings" */ '../components/LSS/LSSSettings.vue');
const LSSKostnTyp          = () => import(/* webpackChunkName: "LSSKostnTyp" */ '../components/LSS/LSSKostnTyp.vue');
const LSSRapOmk            = () => import(/* webpackChunkName: "LSSRapOmk" */ '../components/LSS/LSSRapOmk.vue');
const LSSRapErs            = () => import(/* webpackChunkName: "LSSRapErs" */ '../components/LSS/LSSRapErs.vue');
const LSSRedov             = () => import(/* webpackChunkName: "LSSRedov" */ '../components/LSS/LSSRedov.vue');
const LSSAterrapportering  = () => import(/* webpackChunkName: "LSSAterrapportering" */ '../components/LSS/LSSAterrapportering.vue');
const CronaHR              = () => import(/* webpackChunkName: "CronaHR" */ '../components/CronaHR.vue');
const FerLon               = () => import(/* webpackChunkName: "FerLon" */ '../components/FerLon/FerLon.vue');
const UphLon               = () => import(/* webpackChunkName: "UphLon" */ '../components/UphLon/UphLon.vue');

const Http404 = () => import(/* webpackChunkName: "Http404" */ '../components/Http404.vue');

Vue.use(Router);


const router = new Router({
	mode:   'history',
	routes: [
		process.env.VUE_APP_LONDEMO_LICNR && String(process.env.VUE_APP_LONDEMO_LICNR).startsWith('WEB') ? {
			path:      '/testa-gratis',
			name:      'RequestDemoAccount',
			component: RequestDemoAccount,
		} : null,
		{
			path:     '/',
			redirect: '/infopanel',
		},
		{
			path:      '/infopanel',
			name:      'Infopanel',
			component: Infopanel,
		},
		{
			path:      '/messages',
			name:      'Messages',
			component: Messages,
		},
		{
			path:      '/mySettings',
			name:      'MySettings',
			component: MySettings,
		},
		{
			path:      '/cronahr',
			name:      'CronaHR',
			component: CronaHR,
			meta:      {
				rightsCheck: ({HasPlus, HasLicensOption, LicenseSupplier}) => process.env.VUE_APP_DEBUG === 'true' ? true : !!(HasPlus && !HasLicensOption.HRTIL) && LicenseSupplier === 'CRONA',
			},
		},
		{
			path:      '/documents',
			name:      'Documents',
			component: Documents,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasDocumentsReadAccess({HasPlus}),
			},
		},
		{
			path:      '/lonekartlaggning',
			name:      'Lonekartlaggning',
			component: Lonekartlaggning,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLonekartlaggningAccess({HasPlus}),
			},
		},
		{
			path:      '/semberedning',
			name:      'SemberedningList',
			component: SemberedningList,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasSemBerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/semberedning/:BerId',
			name:      'SemberedningDetails',
			component: SemberedningDetails,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasSemBerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/kontrolluppgift',
			name:      'Kontrolluppgift',
			component: Kontrolluppgift,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasKUBerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/ferLon',
			name:      'FerLon',
			component: FerLon,
			meta:      {
				rightsCheck: ({UserRights, HasUserOption}) => UserRights.hasFerLonReadAccess({HasUserOption}),
			},
		},
		{
			path:      '/uphLon',
			name:      'UphLon',
			component: UphLon,
			meta:      {
				rightsCheck: ({UserRights, HasUserOption}) => UserRights.hasUphLonReadAccess({HasUserOption}),
			},
		},
		{
			path:      '/settings/company',
			name:      'CompanySettings',
			component: CompanySettings,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasCompanySettingsReadAccess(),
			},
		},
		{
			path:      '/registry/impreg',
			name:      'ImpReg',
			component: ImpReg,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasImpRegReadAccess(),
			},
		},
		{
			path:      '/settings/bokforing',
			name:      'BokforingSettings',
			component: BokforingSettings,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBokforingSettingsReadAccess(),
			},
		},
		{
			path:      '/settings/sysdata',
			name:      'SysData',
			component: SysData,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasSysDataReadAccess(),
			},
		},
		{
			path:      '/settings/digitalmailbox',
			name:      'DigitalMailbox',
			component: DigitalMailbox,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasDigitalMailboxSettingsReadAccess(),
			},
		},
		{
			path:      '/settings/integrations',
			name:      'Integrations',
			component: Integrations,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasIntegrationsReadAccess({Roll}),
			},
		},
		{
			path:      '/settings/integrations/@/new',
			name:      'IntegrationsNew',
			component: Integration,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasIntegrationsWriteAccess({Roll}),
			},
		},
		{
			path:      '/settings/integrations/:IntegrationID',
			name:      'Integration',
			component: Integration,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasIntegrationsReadAccess({Roll}),
			},
		},
		{
			path:      '/settings/users',
			name:      'Users',
			component: Users,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasUsersReadAccess({Roll}),
			},
		},
		{
			path:      '/settings/users/@/new',
			name:      'UsersNew',
			component: User,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasUsersWriteAccess({Roll}),
			},
		},
		{
			path:      '/settings/users/:Login',
			name:      'User',
			component: User,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasUsersReadAccess({Roll}),
			},
		},
		{
			path:      '/settings/userRightsGroups',
			name:      'UserRightsGroups',
			component: UserRightsGroups,
			meta:      {
				rightsCheck: ({HasPlus, UserRights, UserAccount: {Roll}}) => UserRights.hasUsersRightsGroupsReadAccess({Roll, HasPlus}),
			},
		},
		{
			path:      '/settings/kortnummer',
			name:      'Kortnummer',
			component: Kortnummer,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasKortnummerReadAccess(),
			},
		},
		{
			path:      '/settings/printouts',
			name:      'Printouts',
			component: Printouts,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPrintoutsReadAccess(),
			},
		},
		{
			path:      '/settings/export',
			name:      'ExportSettings',
			component: ExportSettings,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasExportSettingsReadAccess(),
			},
		},
		// {
		// 	path: '/gettingStarted',
		// 	name: 'GettingStarted',
		// 	component: GettingStarted,
		// },
		{
			path:      '/registry/salaryPeriods',
			name:      'SalaryPeriods',
			component: SalaryPeriods,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasSalaryPeriodsReadAccess(),
			},
		},
		{
			path:      '/registry/Departments',
			name:      'Departments',
			component: Departments,
			meta:      {
				rightsCheck: ({UserRights, FtgInfo: {UseAvdelning}}) => UserRights.hasDepartmentsReadAccess({UseAvdelning}),
			},
		},
		{
			path:      '/registry/Holidays',
			name:      'holidays',
			component: Holidays,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasHolidaysReadAccess(),
			},
		},
		{
			path:      '/registry/fordelningsnycklar',
			name:      'Fordelningsnycklar',
			component: Fordelningsnycklar,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasFordelningsnycklarReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/fordelningsnycklar/@/create',
			name:      'FordelningsnyckelCreate',
			component: Fordelningsnyckel,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasFordelningsnycklarWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/fordelningsnycklar/:KonKey',
			name:      'Fordelningsnyckel',
			component: Fordelningsnyckel,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasFordelningsnycklarReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/arbschema',
			name:      'Arbschema',
			component: Arbschema,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArbSchemaReadAccess(),
			},
		},
		{
			path:      '/registry/arbschema/@/new',
			name:      'ArbschemaNew',
			component: ArbschemaForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArbSchemaWriteAccess(),
			},
		},
		{
			path:      '/registry/arbschema/:SchemaID',
			name:      'ArbschemaForm',
			component: ArbschemaForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArbSchemaReadAccess(),
			},
		},
		{
			path:      '/registry/dagschema',
			name:      'Dagschema',
			component: Dagschema,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasDagSchemaReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/dagschema/@/new',
			name:      'DagschemaNew',
			component: DagschemaForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasDagSchemaWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/dagschema/:SkiftID',
			name:      'DagschemaForm',
			component: DagschemaForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasDagSchemaReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/obregler',
			name:      'OBRegler',
			component: OBRegler,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOBReglerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/obregler/@/new',
			name:      'OBRegelNew',
			component: OBRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOBReglerWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/obregler/:OBRegelID',
			name:      'OBRegelForm',
			component: OBRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOBReglerReadAccess({HasPlus}),
			},
		},

		{
			path:      '/registry/jorregler',
			name:      'JorRegler',
			component: JorRegler,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasJorReglerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/jorregler/@/new',
			name:      'JorRegelNew',
			component: JorRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasJorReglerWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/jorregler/:JorRegelID',
			name:      'JorRegelForm',
			component: JorRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasJorReglerReadAccess({HasPlus}),
			},
		},

		{
			path:      '/registry/berregler',
			name:      'BerRegler',
			component: BerRegler,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasBerReglerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/berregler/@/new',
			name:      'BerRegelNew',
			component: BerRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasBerReglerWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/berregler/:BerRegelID',
			name:      'BerRegelForm',
			component: BerRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasBerReglerReadAccess({HasPlus}),
			},
		},

		{
			path:      '/registry/ovtregler',
			name:      'OvtRegler',
			component: OvtRegler,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOvtReglerReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/ovtregler/@/new',
			name:      'OvtRegelNew',
			component: OvtRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOvtReglerWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/ovtregler/:OvtRegelID',
			name:      'OvtRegelForm',
			component: OvtRegelForm,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasOvtReglerReadAccess({HasPlus}),
			},
		},

		{
			path:      '/registry/tidkoder',
			name:      'TidKoder',
			component: TidKoder,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasTidKoderReadAccess(),
			},
		},
		{
			path:      '/registry/lonkoder',
			name:      'LonKoder',
			component: LonKoder,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasLonKoderReadAccess(),
			},
		},
		{
			path:      '/registry/varugrupper',
			name:      'Varugrupper',
			component: Varugrupper,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasVarugrupperReadAccess(),
			},
		},
		{
			path:      '/registry/friaackardef',
			name:      'FriaAckarDef',
			component: FriaAckarDef,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasVarugrupperReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/egenskaper',
			name:      'Egenskaper',
			component: Egenskaper,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasEgenskaperReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/brukare',
			name:      'BrukarReg',
			component: BrukarReg,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasBrukarRegReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/registry/brukare/@/create',
			name:      'BrukarkortCreate',
			component: Brukarkort,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasBrukarRegWriteAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/registry/brukare/:Brukare',
			name:      'Brukarkort',
			component: Brukarkort,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasBrukarRegReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/registry/accounts',
			name:      'KontoReg',
			component: KontoReg,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasKontoRegReadAccess(),
			},
		},
		{
			path:      '/registry/resenheter',
			name:      'Resultatenheter',
			component: Resultatenheter,
			meta:      {
				rightsCheck: ({UserRights, FtgInfo}) => UserRights.hasResEnhReadAccess(FtgInfo),
			},
		},
		{
			path:      '/registry/employees',
			name:      'Personalregister',
			component: Personalregister,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegReadAccess(),
			},
		},
		{
			path:      '/registry/employees/@/create',
			name:      'PersonkortCreate',
			component: Personkort,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegWriteAccess(),
			},
		},
		{
			path:      '/registry/employees/:AnstNr',
			name:      'Personkort',
			component: Personkort,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegReadAccess(),
			},
		},
		{
			path:      '/registry/employees/@/stdper',
			name:      'StdPersoner',
			component: StdPersoner,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegReadAccess(),
			},
		},
		{
			path:      '/registry/employees/@/stdper/:Id',
			name:      'StdPersonkort',
			component: StdPersonkort,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegReadAccess(),
			},
		},
		{
			path:      '/registry/employees/@/stdper/@/create',
			name:      'StdPersonkortCreate',
			component: StdPersonkort,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegWriteAccess(),
			},
		},
		{
			path:      '/registry/artreg',
			name:      'Loneartsregister',
			component: Loneartsregister,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegReadAccess(),
			},
		},
		{
			path:      '/registry/artreg/:TabellKod/@/create',
			name:      'LoneartFormCreate',
			component: LoneartForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegWriteAccess(),
			},
		},
		{
			path:      '/registry/artreg/:TabellKod/:LonArtNr',
			name:      'LoneartForm',
			component: LoneartForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegReadAccess(),
			},
		},
		{
			path:      '/registry/artsty',
			name:      'Loneartsstyrning',
			component: Loneartsstyrning,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegReadAccess(),
			},
		},
		{
			path:      '/registry/loneartstabeller',
			name:      'Loneartstabeller',
			component: Loneartstabeller,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegReadAccess(),
			},
		},
		{
			path:      '/registry/loneartstabeller/@/create',
			name:      'LoneartstabellCreate',
			component: Loneartstabell,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegWriteAccess(),
			},
		},
		{
			path:      '/registry/loneartstabeller/:TabellKod',
			name:      'Loneartstabell',
			component: Loneartstabell,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasArtRegReadAccess(),
			},
		},
		{
			path:      '/registry/customFormelVariables',
			name:      'CustomFormelVariables',
			component: CustomFormelVariables,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasCustomFormelVariablesReadAccess(),
			},
		},
		{
			path:      '/registry/agreements',
			name:      'Agreements',
			component: Agreements,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasAgreementsReadAccess(),
			},
		},
		{
			path:      '/registry/vacationagreements',
			name:      'VacationAgreements',
			component: VacationAgreements,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasVacationAgreementsReadAccess({HasPlus}),
			},
		},
		{
			path:      '/registry/fackfor',
			name:      'FackFor',
			component: FackFor,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasFackForReadAccess({HasPlus}),
			},
		},
		{
			path:      '/loneuppdrag',
			name:      'LoneuppdragList',
			component: LoneuppdragList,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasLoneuppdragReadAccess(),
			},
		},
		{
			path:      '/loneuppdrag/@/create',
			name:      'LoneuppdragCreate',
			component: Loneuppdrag,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLoneuppdragWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/loneuppdrag/:UppdragId',
			name:      'Loneuppdrag',
			component: Loneuppdrag,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasLoneuppdragReadAccess(),
			},
		},
		{
			path:      '/payslips',
			name:      'PayslipsOverview',
			component: PayslipsOverview,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPayslipsReadAccess(),
			},
		},
		{
			path:      '/fksjukanmalan',
			name:      'FKSjukanmalan',
			component: FKSjukanmalan,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasFKSjukanmalanAccess(),
			},
		},
		{
			path:      '/payslips/:Period',
			name:      'PayslipList',
			component: PayslipList,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPayslipsReadAccess(),
			},
		},
		{
			path:      '/payslips/:Period/:AnstNr',
			name:      'Payslip',
			component: Payslip,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPayslipsReadAccess(),
			},
		},
		{
			path:      '/employerDeclaration',
			name:      'EmployerDeclarationOverview',
			component: EmployerDeclarationOverview,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasEmployerDeclarationReadAccess(),
			},
		},
		{
			path:      '/employerDeclaration/:Period',
			name:      'EmployerDeclaration',
			component: EmployerDeclaration,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasEmployerDeclarationReadAccess(),
			},
		},
		{
			path:      '/bokforingsunderlag',
			name:      'BokforingsunderlagOverview',
			component: BokforingsunderlagOverview,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBookkeepingReadAccess(),
			},
		},
		{
			path:      '/bokforingsunderlagList/:Period',
			name:      'BokforingsunderlagList',
			component: BokforingsunderlagList,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBookkeepingReadAccess(),
			},
			props: true,
		},
		{
			path:      '/bokforingsunderlag/:VerId',
			name:      'Bokforingsunderlag',
			component: Bokforingsunderlag,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBookkeepingReadAccess(),
			},
			props: true,
		},
		{
			path:      '/kalendarie',
			name:      'Kalendarie',
			component: Kalendarie,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				rightsCheck:                         ({UserRights}) => UserRights.hasKalendarieReadAccess(),
			},
		},
		{
			path:      '/tidrapport',
			name:      'Tidrapport',
			component: Tidrapport,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				rightsCheck:                         ({UserRights, HasPlus}) => UserRights.hasTidrapportReadAccess({HasPlus}),
			},
		},
		{
			path:      '/ersattningar',
			name:      'Ersattningar',
			component: Ersattningar,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				rightsCheck:                         ({UserRights}) => UserRights.hasErsattningarReadAccess(),
			},
		},
		{
			path:      '/ersattningar/:AnstNr/:RadId',
			name:      'Ersattning',
			component: ErsattningForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasErsattningarReadAccess(),
			},
		},
		{
			path:      '/ersattningar/:AnstNr/@/new',
			name:      'ErsattningNew',
			component: ErsattningForm,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasErsattningarWriteAccess(),
			},
		},
		{
			path:      '/fos',
			name:      'FOS',
			component: FOS,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasFOSReadAccess(),
			},
		},
		{
			path:      '/fora',
			name:      'Fora',
			component: Fora,
			meta:      {
				rightsCheck: ({UserRights, FtgInfo}) => UserRights.hasForaReadAccess(FtgInfo),
			},
		},
		{
			path:      '/forskottutbetalning',
			name:      'ForskottUtbetalning',
			component: ForskottUtbetalning,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasForskUtbWriteAccess({HasPlus}),
			},
		},
		{
			path:      '/scb',
			name:      'SCB',
			component: SCB,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {ScbStatistik}}) => UserRights.hasSCBReadAccess({HasPlus, ScbStatistik}),
			},
		},
		{
			path:      '/saf',
			name:      'SAF',
			component: SAF,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {SafMedlem}}) => UserRights.hasSAFReadAccess({HasPlus, SafMedlem}),
			},
		},
		{
			path:      '/kfo',
			name:      'KFO',
			component: KFO,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {KfoMedlem}}) => UserRights.hasKFOReadAccess({HasPlus, KfoMedlem}),
			},
		},
		{
			path:      '/kpber',
			name:      'KPBer',
			component: KPBer,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {KPTecknad}}) => UserRights.hasKPBerReadAccess({HasPlus, KPTecknad}),
			},
		},
		{
			path:      '/kpber/:Id',
			name:      'KPBerRad',
			component: KPBerRad,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {KPTecknad}}) => UserRights.hasKPBerReadAccess({HasPlus, KPTecknad}),
			},
		},
		{
			path:      '/losen',
			name:      'LOSEN',
			component: LOSEN,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {UseLosen}}) => UserRights.hasLOSENReadAccess({HasPlus, UseLosen}),
			},
		},
		{
			path:      '/losenpersonal/:LoneperiodStartdatum/:LoneperiodSlutdatum',
			name:      'LOSENPersonal',
			component: LOSENPersonal,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {UseLosen}}) => UserRights.hasLOSENReadAccess({HasPlus, UseLosen}),
			},
		},
		{
			path:      '/losendetails/:LoneperiodStartdatum/:LoneperiodSlutdatum/:AnstNr',
			name:      'LOSENDetails',
			component: LOSENDetails,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {UseLosen}}) => UserRights.hasLOSENReadAccess({HasPlus, UseLosen}),
			},
		},
		{
			path:      '/collectum',
			name:      'Collectum',
			component: Collectum,
			meta:      {
				rightsCheck: ({UserRights, HasPlus, FtgInfo: {ITPTecknad}}) => UserRights.hasCollectumReadAccess({HasPlus, ITPTecknad}),
			},
		},
		{
			path:      '/retrolon',
			name:      'RetroLon',
			component: RetroLon,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasRetroLonReadAccess({HasPlus}),
			},
		},
		{
			path:      '/retrolon/:Id',
			name:      'RetroLonDetails',
			component: RetroLonDetails,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasRetroLonReadAccess({HasPlus}),
			},
		},
		{
			path:      '/lonerevision',
			name:      'Lonerevision',
			component: Lonerevision,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLoneRevisionReadAccess({HasPlus}),
			},
		},
		{
			path:      '/lonerevision/:Id',
			name:      'LonerevisionDetails',
			component: LonerevisionDetails,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLoneRevisionReadAccess({HasPlus}),
			},
		},
		{
			path:      '/narvarostatus',
			name:      'Närvarostatus',
			component: Narvarostatus,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasNarvarostatusAccess(),
			},
		},
		{
			path:      '/rapporter/loneartslista',
			name:      'Loneartslista',
			component: Loneartslista,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLoneartslistaReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/resultatrapport',
			name:      'Resultatrapport',
			component: Resultatrapport,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBruttoloneListaReportAccess(),
			},
		},
		{
			path:      '/rapporter/custom/galenicaKeyFiguresReport',
			name:      'GalenicaKeyFiguresReport',
			component: GalenicaKeyFiguresReport,
			meta:      {
				rightsCheck: ({UserAccount, FtgInfo}) => FtgInfo && validateGalenicaFeaturesEnabled(UserAccount.LicNr, FtgInfo.FtgKey),
			},
		},
		{
			path:      '/rapporter/custom/mazarsSpecialReports',
			name:      'MazarsSpecialReports',
			component: MazarsSpecialReports,
			meta:      {
				rightsCheck: ({UserAccount}) => validateMazarsReportsEnabled(UserAccount.LicNr),
			},
		},
		{
			path:      '/rapporter/bruttolonelista',
			name:      'Bruttolönelista',
			component: Bruttolonelista,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasBruttoloneListaReportAccess(),
			},
		},
		{
			path:      '/rapporter/lonestatistik',
			name:      'Lönestatistik',
			component: Lonestatistik,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasLonestatistikReportAccess(),
			},
		},
		{
			path:      '/rapporter/Saldoskuld',
			name:      'Saldoskuld',
			component: Saldoskuld,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasSaldoskuldReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/laslista',
			name:      'LASLista',
			component: LASLista,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLASListaReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/rehablista',
			name:      'Rehablista',
			component: Rehablista,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasRehablistaReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/fteHeadcount',
			name:      'FTEHeadcount',
			component: FTEHeadcount,
			meta:      {
				rightsCheck: ({UserRights, HasUserOption}) => UserRights.hasFTEHeadcountReportAccess({HasUserOption}),
			},
		},
		{
			path:      '/rapporter/timeJournals',
			name:      'TimeJournals',
			component: TimeJournals,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasTimeJournalsReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/avtalslista',
			name:      'Avtalslista',
			component: AvtalsLista,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasAgreementsReadAccess(),
			},
		},
		{
			path:      '/rapporter/personallista',
			name:      'Personallista',
			component: Personallista,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerListaReportAccess(),
			},
		},
		{
			path:      '/rapporter/brukarreglista',
			name:      'BrukarRegLista',
			component: BrukarRegLista,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasBrukarListaReportAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/rapporter/brukarperlista',
			name:      'BrukarPerLista',
			component: BrukarPerLista,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasBrukarListaReportAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/rapporter/personkort',
			name:      'PersonkortRap',
			component: PersonkortRap,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerRegReportAccess(),
			},
		},
		{
			path:      '/rapporter/perkontakt',
			name:      'PerKontaktRap',
			component: PerKontaktRap,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasPerKontaktReportAccess(),
			},
		},
		{
			path:      '/rapporter/debiteringskonto',
			name:      'Debiteringskonto',
			component: Debiteringskonto,
			meta:      {
				rightsCheck: ({UserRights, UserAccount: {Roll}}) => UserRights.hasDebKontoReportAccess({Roll}),
			},
		},
		{
			path:      '/rapporter/fackavgifter',
			name:      'Fackavgifter',
			component: Fackavgifter,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasFackAvgReportAccess({HasPlus}),
			},
		},
		{
			path:      '/rapporter/loneutmatning',
			name:      'Loneutmatning',
			component: Loneutmatning,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasLoneutmatningReportAccess(),
			},
		},
		{
			path:      '/rapporter/loneutveckling',
			name:      'Loneutveckling',
			component: Loneutveckling,
			meta:      {
				rightsCheck: ({UserRights, HasPlus}) => UserRights.hasLoneutvecklingReportAccess({HasPlus}),
			},
		},
		{
			path:      '/datatreesearch',
			name:      'DataTreeSearch',
			component: DataTreeSearch,
			meta:      {
				rightsCheck: ({UserRights}) => UserRights.hasDataTreeSearchAccess(),
			},
		},
		{
			path:      '/lss/overview',
			name:      'LSSOverview',
			component: LSSBeredningOverview,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSBerReadAccess({userLSSEnabled}),
			},
		},
		{
			path:     '/lss',
			redirect: '/lss/overview',
			meta:     {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSBerReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/settings',
			name:      'LSSSettings',
			component: LSSSettings,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSSettingsReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/kostntyp',
			name:      'LSSKostnTyp',
			component: LSSKostnTyp,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSKostnTypReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/rapOmk',
			name:      'LSSRapOmk',
			component: LSSRapOmk,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSRapOmkReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/rapErs',
			name:      'LSSRapErs',
			component: LSSRapErs,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSRapErsReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/redov',
			name:      'LSSRedov',
			component: LSSRedov,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSRedovReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/aterrapportering',
			name:      'LSSAterrapportering',
			component: LSSAterrapportering,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSAterrapporteringReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/:LSSPeriod',
			name:      'LSSBeredningList',
			component: LSSBeredningList,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSBerReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '/lss/beredning/:Id',
			name:      'LSSBeredning',
			component: LSSBeredning,
			meta:      {
				rightsCheck: ({UserRights, userLSSEnabled}) => UserRights.hasLSSBerReadAccess({userLSSEnabled}),
			},
		},
		{
			path:      '*',
			name:      'Http404',
			component: Http404,
		},
	].filter((v) => v),
});

router.checkHasUnsavedChanges = (next, target) => {
	const {$children = []} = router.app;
	const [appView] = $children;

	if(appView) {
		return appView.checkHasUnsavedChanges((wantsToLeave) => {
			if(wantsToLeave) {
				if(target) {
					target = {...target};
					target.params = target.params || {};
					target.params.skipUnsavedChanges = true;

					return router.push(target, noop, noop);
				}

				return (next || noop)(true);
			}
			
			return next(false);
		});
	}

	return next();
};

router.beforeEach((to, from, next) => {
	const skipUnsavedChangesCheck = to.params && to.params.skipUnsavedChanges === true;
	
	if(skipUnsavedChangesCheck) {
		return next();
	}

	return router.checkHasUnsavedChanges(next, to);
});

router.checkRight = (route, redirectURL) => {
	const {state} = store;
	const {rightsCheck = null} = route.meta || {};

	if(
		state.Authenticated &&
		'function' === typeof rightsCheck &&
		!(state.UserRights instanceof PendingUserRights) &&
		rightsCheck({...state, userLSSEnabled: router.app.userLSSEnabled}) !== true
	) {
		router.push(redirectURL);
	}
};


router.afterEach((to) => {
	if(to && to.meta) {
		router.checkRight(to, '/infopanel');
	}
});

export default router
